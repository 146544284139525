/* eslint-disable @typescript-eslint/naming-convention */

// Packages
import React from 'react'
import { ArrowProps } from 'react-multi-carousel/lib/types'

// Icons
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'

// Styles
import { cardStyles } from '../styles/home.styles'

interface ExtendedArrowProps extends ArrowProps {
    url: string
}

function NextArrow(props: ExtendedArrowProps): React.ReactElement {
    const { onClick, carouselState, url } = props
    const style = cardStyles()

    const isLastItem = carouselState ? Boolean(carouselState.currentSlide === carouselState.totalItems - 1) : false

    if (isLastItem) {
        return (
            <div className={style.nextArrow} onClick={onClick}>
                <a href={url} target="_blank" rel="noreferrer">
                    <ArrowForwardIosIcon />
                </a>
            </div>
        )
    }

    return (
        <div className={style.nextArrow} onClick={onClick}>
            <ArrowForwardIosIcon />
        </div>
    )
}

function PrevArrow(props: ExtendedArrowProps) {
    const { onClick, carouselState } = props
    const style = cardStyles()

    const isFirstItem = carouselState ? Boolean(carouselState.currentSlide === 0) : false

    if (isFirstItem) {
        return <div className={style.nextArrow} />
    }

    return (
        <div className={style.previousArrow} onClick={onClick}>
            <ArrowBackIosIcon />
        </div>
    )
}

interface ImageCarouselSettings {
    url: string
}

export const imageCarouselSettings = ({ url }: ImageCarouselSettings) => ({
    customLeftArrow: <PrevArrow url={url} />,
    customRightArrow: <NextArrow url={url} />,
    renderArrowsWhenDisabled: true,
    draggable: false,
    swipeable: false,
    items: 1,
    responsive: {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
        },
    },
})
