// Packages
import { Tooltip } from '@material-ui/core'
import React from 'react'
import Router from 'next/router'
import { sendAnalytics } from '@gjc/classi-react-commons/core/analytics'
import { AnalyticsEvent } from '@gjc/classi-react-commons/types/Analytics'

// Styles
import { searchedBrandsStyles } from './searched-brands.styles'

// Utils
import { StringUtils } from '../../utils/StringUtils'

export interface PopularBrandCard {
    logo: string
    label: string
}

interface SearchedBrandsProps {
    data: Array<PopularBrandCard>
    category: string
}

const SearchBrands = ({ data, category }: SearchedBrandsProps) => {
    const styles = searchedBrandsStyles()

    return (
        <div className={styles.wrapper}>
            {data?.map(card => (
                <Tooltip title={card.label} key={card.label}>
                    <div
                        className={`${styles.card} marcas-mais-buscadas`}
                        onClick={() => (
                            sendAnalytics(AnalyticsEvent.Search, { category: category }),
                            Router.push(
                                `/${category.toLowerCase()}/br/${StringUtils.cleanString(
                                    card.label.split(' - ').reverse()[0]
                                )}`
                            )
                        )}
                        style={{
                            backgroundImage: `url(/svg/${StringUtils.cleanString(
                                card.label.replaceAll(' ', '')
                            ).toLowerCase()}.svg)`,
                            backgroundSize: '40px 40px',
                        }}
                    />
                </Tooltip>
            ))}
        </div>
    )
}

export default SearchBrands
