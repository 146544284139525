// Packages
import React, { useEffect, useState } from 'react'
import Head from 'next/head'
import Footer from '@gjc/classi-react-commons/Components/Footer/Footer'
import Topbar from '@gjc/classi-react-commons/Components/Topbar/Topbar'
import { Grid } from '@material-ui/core'
import { Vitrines } from '@gjc/classi-react-commons/types/Vitrines'
import { useKeycloak } from '@react-keycloak/nextjs'

// Styles
import { styles } from './layout.styles'

// Assets
import MenuBackgroundImage from '../../../public/img/capa-poly-bg.jpg'
import InstanaScript from '../../utils/InstanaScript'

interface UserInfoProps {
    username: string
    profileImage?: string
    email: string
}

interface KeycloakUserInfo {
    email: string
    family_name: string
    given_name: string
    name: string
    preferred_username: string
    sub: string
}

const Layout: React.FC = ({ children }) => {
    const [userInfo, setUserInfo] = useState<UserInfoProps>()
    const classes = styles()

    const { keycloak } = useKeycloak()

    useEffect(() => {
        if (keycloak?.authenticated) {
            const loadUserInfo = keycloak?.loadUserInfo() as unknown as Promise<KeycloakUserInfo>

            loadUserInfo.then((user: KeycloakUserInfo) => {
                setUserInfo({
                    username: user?.given_name,
                    email: user?.email,
                })
            })
        } else {
            setUserInfo(undefined)
        }
    }, [keycloak, keycloak?.authenticated])

    return (
        <Grid container className={classes.root}>
            <Topbar
                user={userInfo}
                type={Vitrines.CARROS}
                onLogin={() => keycloak?.login()}
                onRegister={() => keycloak?.register()}
                onExit={() => keycloak?.logout()}
                backgroundImage={MenuBackgroundImage}
            />
            <Head>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Head>
            <Grid item xs={12}>
                {children}
            </Grid>
            <Footer />
            <InstanaScript />
        </Grid>
    )
}

export default Layout
