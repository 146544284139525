/**
 * String Utils packet
 */
export const StringUtils = {
    cleanString: function (value: string) {
        if (value) {
            return this.normalizeString(value).replace(/\s+/g, '+').replace(/\//g, '+').replace(/\./g, '')
        }

        return ''
    },
    normalizeString: function (value: string) {
        return value
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
    },
    getPrimeiroMatch: function (value: string, regex: string) {
        return this.getMatch(value, regex, 0)
    },
    getMatch: function (value: string, regex: string, index: number) {
        if (value) {
            const match = value.match(regex)

            if (match && match.length) {
                if (Number.isInteger(index) && index >= 0) {
                    return match[index]
                }

                return match
            }
        }

        return null
    },
}
