/* eslint-disable @next/next/no-img-element */

// Packages
import React, { useEffect } from 'react'
import Router from 'next/router'
import type { GetServerSideProps } from 'next'
import { Box, Container, Grid, Paper, Hidden, Typography } from '@material-ui/core'
import { Filter } from '@gjc/classi-react-commons/Components/CardBusca/CardBusca'
import { initAnalytics, sendPageView, sendAnalytics } from '@gjc/classi-react-commons/core/analytics'
import MainAdvertiser from '@gjc/classi-react-commons/Components/MainAdvertiser'
import CardBusca from '@gjc/classi-react-commons/Components/CardBusca'
import { AnalyticsEvent } from '@gjc/classi-react-commons'
import { debounce } from 'lodash'
import { dehydrate, QueryClient } from '@tanstack/react-query'
import { Options } from '@gjc/classi-react-commons/Components/Autocomplete/Autocomplete'
import HomeCards from '@gjc/classi-react-commons/Components/Cards/HomeCards'
import { NextSeo } from 'next-seo'
import Carousel from 'react-multi-carousel'
import { useKeycloak } from '@react-keycloak/nextjs'

// Api
import {
    useGetBrandsAndModelsQuery,
    useGetEmphasisAnnouncementsQuery,
    useGetFilterSelectQuery,
    useGetPopularBrandsQuery,
    vehiclesEndpoints,
} from '../api/vehiclesApi'
import { FilterResponse, ReferrerType } from '../api/types'

// Components
import Layout from '../components/Layout'
import SearchBrands, { PopularBrandCard } from '../components/SearchedBrands/index'
import CarDetails from '../components/CarDetails'
import ImageNotFound from '../components/ImageNotFound'
import Advertising from '../components/Advertising/Advertising'
import FavoriteIcon from '../components/FavoriteIcon/FavoriteIcon'

// Styles
import 'react-multi-carousel/lib/styles.css'
import { cardStyles, textStyles } from '../styles/home.styles'

// Utils
import { generateUrlFromHome } from '../utils/formatUrl'
import { validatePriority } from '../utils/methods/validatePriority'
import { imageCarouselSettings } from '../utils/carouselSettings'

const Home = (referrer: ReferrerType): React.ReactNode => {
    const textClass = textStyles()
    const cardClass = cardStyles()

    const { keycloak } = useKeycloak()
    const { data: filterSelects } = useGetFilterSelectQuery()

    const [category, setCategory] = React.useState(filterSelects?.categoria[0].valor || '')
    const [search, setSearch] = React.useState<string>('')
    const [brandModel, setBrandModel] = React.useState<Options>()

    const { data: brandsModelsData } = useGetBrandsAndModelsQuery({ search, category, isEnabled: Boolean(search) })
    const { data: popularBrands } = useGetPopularBrandsQuery({ category, isEnabled: Boolean(category) })
    const { data: announcementList } = useGetEmphasisAnnouncementsQuery({
        pagina: 0,
        paginado: true,
        qtdRegistros: 3,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceSearchHandler = React.useCallback(debounce(setSearch, 200), [])

    const brandsModels = React.useMemo(() => {
        return (
            brandsModelsData?.reduce(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (acc: any, cur) =>
                    acc.concat(
                        cur.options.map(item => {
                            let agrupamentoLabel = ''

                            if (cur.descricao === 'marcas') {
                                agrupamentoLabel = 'Marca'

                                const splitedBrand = item.descricao.split('-')

                                splitedBrand?.shift()

                                return {
                                    ...item,
                                    descricao: splitedBrand?.join('-').replaceAll(' ', ''),
                                    valor: item.valor.replace(/[^a-zA-Z]+/g, ''),
                                    agrupamento: agrupamentoLabel,
                                }
                            }

                            if (cur.descricao === 'modelos') {
                                agrupamentoLabel = 'Modelo'
                            }

                            if (cur.descricao === 'versao') {
                                agrupamentoLabel = 'Versão'
                            }

                            return {
                                ...item,
                                agrupamento: agrupamentoLabel,
                            }
                        })
                    ),
                []
            ) || []
        )
    }, [brandsModelsData])

    const handleSelectCategory = (categoryName: string) => {
        const newCategory = filterSelects?.categoria.find(categoria => categoria.descricao === categoryName)
            ?.valor as string

        setCategory(newCategory)
    }

    const selectedCategory: Filter = React.useMemo(() => {
        return (
            (filterSelects?.categoria.find(categoria => categoria.valor === category)?.descricao as Filter) ||
            Filter.carro
        )
    }, [category, filterSelects?.categoria])

    const handleSearch = () => {
        sendAnalytics(AnalyticsEvent.Search, { category: selectedCategory })

        return Router.push(
            `${generateUrlFromHome({
                category: selectedCategory,
                brand: brandModel,
            })}&categoria=${selectedCategory.toLowerCase()}`,
            generateUrlFromHome({
                category: selectedCategory,
                brand: brandModel,
            })
        )
    }

    const handleSelectBrandModel = (brandModel: Options) => {
        setBrandModel(brandModel)
    }

    const sendClickAnalyticEvent = (id: number) => {
        sendAnalytics(AnalyticsEvent.ClickAd, { id })
    }

    useEffect(() => {
        initAnalytics('vitrine_veiculos_capa', 'vitrine_veiculos', keycloak?.profile?.email)
        sendPageView({ referrer })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Layout>
            <NextSeo
                title="Carros novos, seminovos e usados - Classi"
                description="Comprar, vender ou alugar carros é no Classificados mais tradicional de Goiás. Encontre as melhores ofertas de veículos novos, seminovos e usados."
                openGraph={{
                    title: 'Carros novos, seminovos e usados - Classi',
                    description:
                        'Comprar, vender ou alugar carros é no Classificados mais tradicional de Goiás. Encontre as melhores ofertas de veículos novos, seminovos e usados.',
                    images: [
                        {
                            url: 'https://carros.classi.com.br/assets/imgs/logo-classi.svg',
                            width: 800,
                            height: 929,
                            alt: 'Classi carros',
                            type: 'image/svg',
                        },
                    ],
                }}
            />
            <Grid container direction="column">
                <Grid container item xs={12} justifyContent="center">
                    <Hidden smDown>
                        <Advertising
                            name="div-gpt-ad-1513338245081-1"
                            adUnit="/54600456/Classi_v_capa_970x150"
                            type="capa"
                            size={[970, 150]}
                        />
                    </Hidden>
                    <Hidden smUp>
                        <Advertising
                            name="div-gpt-ad-1513340433930-4"
                            adUnit="/54600456/Classi_v_busca_300x250"
                            type="capa"
                            size={[300, 250]}
                        />
                    </Hidden>
                </Grid>
                <Grid item xs={12}>
                    <div className={textClass.root}>
                        <Typography variant="h1" className={textClass.title}>
                            ENCONTRE O CARRO DOS SEUS SONHOS
                        </Typography>
                    </div>
                </Grid>
                <Container style={{ maxWidth: '980px' }}>
                    <Paper className={cardClass.root} elevation={2} variant="outlined" square>
                        <CardBusca
                            searchType={selectedCategory}
                            onChangeType={handleSelectCategory}
                            onInputChange={debounceSearchHandler}
                            onChange={handleSelectBrandModel}
                            onSearch={() => handleSearch()}
                            options={brandsModels}
                            label=""
                            getOptionLabel={() => ''}
                        />
                        {Boolean(popularBrands?.length) && (
                            <Grid>
                                <div>
                                    <Typography className={textClass.mostSearchedBrandsTitle}>
                                        Marcas mais buscadas
                                    </Typography>
                                    <SearchBrands
                                        category={selectedCategory}
                                        data={popularBrands as PopularBrandCard[]}
                                    />
                                </div>
                            </Grid>
                        )}
                    </Paper>
                    <Box component="h3" className={textClass.highlights}>
                        Destaques
                    </Box>
                    <div className={cardClass.highlightsContainer}>
                        {announcementList?.anuncios.map((item, index) => (
                            <div className={cardClass.cardContainer} key={item.id}>
                                <HomeCards
                                    homeImageClassName={`destaque-home-imagem-${index + 1}`}
                                    homeTitleClassName={`destaque-home-titulo-${index + 1}`}
                                    onClick={() => sendClickAnalyticEvent(item.id)}
                                    badge={validatePriority(item)}
                                    favorite={<FavoriteIcon id={`${item.id}`} />}
                                    image={
                                        item.imagens.length > 0 ? (
                                            <Carousel
                                                {...imageCarouselSettings({
                                                    url: item.isFeirao ? item.urlFeirao : item.url,
                                                })}
                                                containerClass={cardClass.carouselContainer}
                                            >
                                                {item.imagens.slice(0, 3).map(value => (
                                                    <img
                                                        key={value}
                                                        src={
                                                            'https://imgproxy.ojc.com.br/insecure/auto/300/189/ce/0/plain/' +
                                                            value
                                                        }
                                                        height="100%"
                                                        width="100%"
                                                        alt={item.titulo}
                                                        style={{ objectFit: 'cover' }}
                                                    />
                                                ))}
                                            </Carousel>
                                        ) : (
                                            <ImageNotFound />
                                        )
                                    }
                                    url={item.url}
                                    title={item.titulo}
                                    characteristics={
                                        <CarDetails
                                            color={item.corVeiculo}
                                            yearFabrication={item.anoFabricacaoVersao}
                                            fuelType={item.combustivel}
                                            exchange={item.cambio}
                                        />
                                    }
                                    value={item.valor}
                                />
                            </div>
                        ))}
                    </div>
                </Container>
                <Hidden smDown>
                    <Grid container justifyContent="center" item>
                        <Advertising
                            name="div-gpt-ad-1513340433930-3"
                            adUnit="/54600456/Classi_v_busca_600x300"
                            type="capa"
                            size={[600, 300]}
                        />
                    </Grid>
                </Hidden>
                <Grid item>
                    <MainAdvertiser
                        mainTitle="quer vender seu carro?"
                        redirectionUrl={process.env.NEXT_PUBLIC_URL_ANUNCIE as string}
                    />
                </Grid>
            </Grid>
        </Layout>
    )
}

export const getServerSideProps: GetServerSideProps = async ctx => {
    const queryClient = new QueryClient()

    await queryClient.prefetchQuery({
        queryKey: ['useGetEmphasisAnnouncements'],
        queryFn: () =>
            vehiclesEndpoints({ token: '' }).getAnnouncementsList({
                pagina: 0,
                paginado: true,
                qtdRegistros: 3,
            }),
    })

    await queryClient.prefetchQuery({
        queryKey: ['getFiltersSelects'],
        queryFn: () => vehiclesEndpoints({ token: '' }).getFiltersSelects(),
    })

    const defaultFilters: FilterResponse = queryClient.getQueryData(['getFiltersSelects']) as FilterResponse
    const defaultCategory: string = defaultFilters?.categoria?.[0].valor

    await queryClient.prefetchQuery({
        queryKey: ['getPopularBrands', defaultCategory],
        queryFn: () => vehiclesEndpoints({ token: '' }).getPopularBrands({ category: defaultCategory }),
    })

    return {
        props: {
            dehydratedState: dehydrate(queryClient),
            referrer: ctx.req.headers?.referer || null,
        },
    }
}

export default Home
