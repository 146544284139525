// Packages
import { makeStyles, createStyles } from '@material-ui/core/styles'

export const styles = makeStyles(() =>
    createStyles({
        root: {
            '& .MuiGrid-spacing-xs-2': {
                margin: 0,
            },
        },
    })
)
