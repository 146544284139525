// Packages
import { makeStyles } from '@material-ui/core/styles'

export const textStyles = makeStyles(theme => ({
    root: {
        margin: '30px 0 15px',
    },
    title: {
        color: theme.palette.primary.main,
        fontWeight: 700,
        fontSize: 46,
        textAlign: 'center',
        paddingLeft: 20,
        paddingRight: 20,
        '@media (max-width:780px)': {
            fontSize: 32,
        },
    },

    mostSearchedBrandsTitle: {
        fontWeight: 600,
        fontSize: 22,
        color: theme.palette.primary.main,
        textAlign: 'left',

        [theme.breakpoints.down('sm')]: {
            fontSize: 18,
        },
    },

    highlights: {
        fontSize: 22,
        color: theme.palette.primary.main,
        margin: '0 0 10px 0',

        [theme.breakpoints.down('sm')]: {
            fontSize: 18,
        },
    },
}))

export const cardStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        margin: '0 0 40px',
        padding: '30px 20px',
    },

    highlightsContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        marginBottom: 20,

        [theme.breakpoints.down('sm')]: {
            scrollSnapType: 'mandatory',
            overflowX: 'scroll',
            scrollBehavior: 'smooth',
            gap: '5%',
            '&::-webkit-scrollbar': { display: 'none' },
        },
    },

    cardContainer: {
        [theme.breakpoints.down('md')]: {
            flexShrink: 0,
            scrollSnapAlign: 'start',
            scrollSnapStop: 'always',
            width: 'fit-content',
            padding: '5px',
        },
    },

    carouselContainer: {
        height: '100%',
        width: '100%',
        alignItems: 'initial',
    },

    nextArrow: {
        position: 'absolute',
        display: 'block',
        top: '50%',
        right: 10,
        color: 'white',
        padding: 0,
        transform: 'translate(0, -50%)',
        zIndex: 2,
        cursor: 'pointer',
    },

    previousArrow: {
        position: 'absolute',
        display: 'block',
        top: '50%',
        left: 16,
        color: 'white',
        padding: 0,
        transform: 'translate(0, -50%)',
        zIndex: 2,
        cursor: 'pointer',
    },
}))
