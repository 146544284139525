// Packages
import { makeStyles } from '@material-ui/core/styles'

const searchedBrandsStyles = makeStyles(theme => ({
    wrapper: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 60px)',
        width: '100%',
        flexWrap: 'wrap',
        margin: '10px auto',
        justifyContent: 'space-between',
        [theme.breakpoints.down('lg')]: {
            gridRow: 2,
            rowGap: '10px',
        },
        [theme.breakpoints.down('md')]: {
            gridRow: 2,
        },
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: 'repeat(4, 60px)',
            gridTemplateRow: 'repeat(3, 5px)',
            justifyContent: 'space-around',
        },
    },
    card: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        padding: '10px',
        backgroundColor: 'none',
        height: '60px',
        width: '60px',
        border: '1px solid #CD181E',
        borderRadius: 8,
        cursor: 'pointer',
        backgroundSize: 'calc(100% - 10px)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundHover: '#CD181E',
    },
}))

export { searchedBrandsStyles }
