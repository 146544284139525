// Packages
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const detailsStyle = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'grid',
            gridTemplateColumns: 'repeat(4, minmax(10%, max-content));',
            maxWidth: 450,
            justifyContent: 'space-between',
        },

        item: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },

        icon: {
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },

        iconLabel: {
            fontSize: 9,
            lineHeight: '12px',
            color: '#626262',
            marginTop: 5,
            textAlign: 'center',
        },
    })
)

export { detailsStyle }
