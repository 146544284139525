// Packages
import React, { FC } from 'react'
import { Calendar } from '@gjc/classi-react-commons/Icons/Calendar'
import { FuelIcon } from '@gjc/classi-react-commons/Icons/FuelIcon'
import { ColorIcon } from '@gjc/classi-react-commons/Icons/ColorIcon'
import { ExchangeIcon } from '@gjc/classi-react-commons/Icons/ExchangeIcon'

// Styles
import { detailsStyle } from './card-details.styles'

interface CarDetailsProps {
    color: string
    yearFabrication: string
    fuelType: string
    exchange: string
}

const CarDetails: FC<CarDetailsProps> = ({ color, yearFabrication, fuelType, exchange }) => {
    const style = detailsStyle()

    return (
        <div className={style.container}>
            {Boolean(yearFabrication) && (
                <div className={style.item}>
                    <div className={style.icon}>
                        <Calendar />
                    </div>
                    <span className={style.iconLabel}>
                        Ano <br />
                        {yearFabrication}
                    </span>
                </div>
            )}
            {Boolean(fuelType) && (
                <div className={style.item}>
                    <div className={style.icon}>
                        <FuelIcon />
                    </div>
                    <span className={style.iconLabel}>
                        Combustível <br />
                        {fuelType}
                    </span>
                </div>
            )}
            {Boolean(color) && (
                <div className={style.item}>
                    <div className={style.icon}>
                        <ColorIcon />
                    </div>
                    <span className={style.iconLabel}>
                        Cor <br />
                        {color}
                    </span>
                </div>
            )}
            {Boolean(exchange) && (
                <div className={style.item}>
                    <div className={style.icon}>
                        <ExchangeIcon />
                    </div>
                    <span className={style.iconLabel}>
                        Câmbio <br />
                        {exchange}
                    </span>
                </div>
            )}
        </div>
    )
}

export default CarDetails
