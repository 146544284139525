import React, { useEffect } from 'react'

const InstanaScript = () => {
    useEffect(() => {
        // Inject inline script
        const inlineScript = document.createElement('script')
        inlineScript.type = 'text/javascript'
        inlineScript.innerHTML = `
      (function(s,t,a,n){s[t]||(s[t]=a,n=s[a]=function(){n.q.push(arguments)},
      n.q=[],n.v=2,n.l=1*new Date)})(window,"InstanaEumObject","ineum");

      ineum('reportingUrl', 'https://eum-red-saas.instana.io');
      ineum('key', 'DJHpLNyEQqOHo1DKKUMYQQ');
      ineum('trackSessions');
    `
        document.head.appendChild(inlineScript)

        // Inject external script
        const externalScript = document.createElement('script')
        externalScript.defer = true
        externalScript.crossOrigin = 'anonymous'
        externalScript.src = 'https://eum.instana.io/1.7.2/eum.min.js'
        externalScript.integrity = 'sha384-cgeSlevgebehPauohUhsnAeBrpjXzaj94mSv3L2EXjCQH0RRb9xSQ2ErGOWkthIJ'
        document.head.appendChild(externalScript)

        return () => {
            // Cleanup scripts when component unmounts
            document.head.removeChild(inlineScript)
            document.head.removeChild(externalScript)
        }
    }, [])

    return null
}

export default InstanaScript
