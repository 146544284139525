// Packages
import { Options } from '@gjc/classi-react-commons/Components/Autocomplete/Autocomplete'
import { omit } from 'lodash'

// Models
import { SearchFilterProps } from '../models/filter/filter.model'

// Utils
import { StringUtils } from '../utils/StringUtils'

interface GenerateUrlFromHomeProps {
    category: string
    brand?: Options
}

const getUfFromLocation = (location: string): string => {
    const splitedLocation = location?.replaceAll(' ', '')?.split('-')
    const uf = splitedLocation?.reverse()[0]?.toLowerCase()

    return StringUtils.cleanString(uf) || 'br'
}

const getCityFromLocation = (location: string): string => {
    const splitedLocation = location?.split('-')

    if (splitedLocation?.length > 1) {
        const city = splitedLocation[0].toLowerCase().replaceAll(' ', '-').slice(0, -1)

        return StringUtils.cleanString(city)
    }

    return ''
}

const getBrandFromBrandModel = (brandModel: string): string => {
    const splitedBrandModel = brandModel?.split(', ')

    const brand = splitedBrandModel?.reverse()[0]?.toLowerCase().replaceAll(' ', '')

    return StringUtils.cleanString(brand) || ''
}

const getModelFromBrandModel = (brandModel: string): string => {
    const splitedBrandModel = brandModel?.split(', ')

    if (splitedBrandModel?.length === 2) {
        const model = StringUtils.cleanString(splitedBrandModel[0])

        return model || ''
    }

    if (splitedBrandModel?.length === 3) {
        const model = StringUtils.cleanString(splitedBrandModel[1])

        return model || ''
    }

    return ''
}

const generateUrlQuery = (filter: SearchFilterProps) => {
    const queryObj = omit(filter, [
        'anunciante',
        'categoria',
        'localizacao',
        'paginado',
        'marcaModelo',
        'marcaModeloVersao',
    ])

    if (filter.marcaModelo?.agrupamento === 'Versão') {
        queryObj.versao = filter.marcaModelo.descricao?.split(', ')?.[0]
    }

    const formatedQuery = Object.keys(queryObj)
        .map((value: string) => {
            const key = value as keyof SearchFilterProps

            if (Array.isArray(queryObj[key]) && Array.from(queryObj[key] as unknown as [])?.length === 0) {
                // eslint-disable-next-line array-callback-return
                return
            }

            if (key === 'qtdRegistros' && queryObj.qtdRegistros === 12) {
                return ''
            }

            if (key === 'ordenacao' && queryObj.ordenacao === 'RELEVANCIA') {
                return ''
            }

            if (key === 'versao') {
                return `versao=${queryObj[key]}`
            }

            return filter[key] ? `${key}=${filter[key]}` : ''
        })
        .filter(Boolean)
        .join('&')

    return formatedQuery
}

export const generateUrlFromHome = (filter: GenerateUrlFromHomeProps) => {
    let newUrl = ''
    let query = ''

    newUrl += filter.category ? `/${filter.category.toLowerCase()}` : ''

    newUrl += '/br'

    if (filter.brand?.agrupamento === 'Versão') {
        const versao = filter.brand.descricao.split(', ')[0].toLowerCase()

        const marca = filter.brand.descricao.split(', ').reverse()[0].toLowerCase().replaceAll(' ', '')
        const modelo = filter.brand.descricao.split(', ').reverse()[1].toLowerCase().replaceAll(' ', '')

        newUrl += `/${marca}/${modelo}`
        query += `&versao=${versao}`
    }

    if (filter.brand?.agrupamento === 'Modelo') {
        const marca = filter.brand.descricao.split(', ').reverse()[0].toLowerCase().replaceAll(' ', '')
        const modelo = filter.brand.descricao.split(', ').reverse()[1].toLowerCase()

        newUrl += `/${marca}/${modelo}`
    }

    if (filter.brand?.agrupamento === 'Marca') {
        const marca = filter.brand.descricao.split(' - ').reverse()[0].toLowerCase()

        newUrl += `/${marca}`
    }

    newUrl += query

    return newUrl
}

export const generateUrlFromSearchFilter = (filter: SearchFilterProps) => {
    let newUrl = ''

    const query = generateUrlQuery(filter)

    const anunciante = filter.anunciante ? StringUtils.cleanString(filter.anunciante.descricao) : null
    const categoria = filter.categoria === '165' ? 'carro' : 'pesado'
    const uf = getUfFromLocation(filter?.localizacao?.valor || '')
    const cidade = getCityFromLocation((filter?.localizacao?.valor as string) || '')
    const marca = getBrandFromBrandModel((filter?.marcaModelo?.valor as string) || '')
    const modelo = getModelFromBrandModel((filter?.marcaModelo?.valor as string) || '')

    newUrl += `${anunciante ? '/' + anunciante : ''}/${categoria}/${uf}${cidade ? '-' + cidade : ''}${
        marca ? '/' + marca : ''
    }${modelo ? '/' + modelo : ''}`

    return newUrl + '?' + query
}
